import * as React from "react"

import { PageProps, graphql } from "gatsby"
import Layout from "../components/layout";
import Seo from "../components/seo";
import RecipeCard from "../components/recipe";


type DataProps = {

    cps: {
        allRecipes: any[];
    }
}


export const query = graphql`

    query LoadAllRecipes {
        cps {
            allRecipes {
                id
                name
                items {
                    quantity
                    ingredientId
                    link
                    ingredient {
                        name
                        parent
                        masterLink
                    }
                }
                preparation
                link
                iceType {
                    id
                    name
                    icon
                    link
                }
                glassType {
                    id
                    name
                    icon
                    link
                }
            }
        }
    }

`
const AllRecipes: React.FC<PageProps<DataProps>> = ({data, path}) => {

    const [recipes] = React.useState(data.cps.allRecipes);

    return (
        <Layout>
            <div className="flex flex-col items-center">
                <Seo title="Cocktail Pantry Search All Recipes"/>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-8">
                    {recipes && recipes.length > 0 &&

                        recipes.map((recipe) => {
                            return (
                                <RecipeCard key={recipe.id} recipe={recipe} />
                            )
                        })
                    }
                </div>
            </div>
        </Layout>
    )
}

export default AllRecipes;